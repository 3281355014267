import { NavLink } from "react-router-dom";
import isInternalLink from "is-internal-link";

const NavOption = ({ optionName, linkTo }) => {
  const styles =
    "py-2 px-4 rounded-full text-sm md:text-xl hover:shadow-xl hover:bg-logo-creme font-semibold";
  return isInternalLink(linkTo) ? (
    <NavLink
      to={linkTo}
      className={({ isActive }) => {
        return `${styles} ${
          isActive ? "bg-logo-orange hover:bg-logo-orange" : ""
        }`;
      }}
    >
      {optionName}
    </NavLink>
  ) : (
    <a href={linkTo} className={styles}>
      {optionName}
    </a>
  );
};

export default NavOption;
