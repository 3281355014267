import PostList from "components/organisms/PostList";

const Home = () => {
  return (
    <section className="flex justify-center">
      <PostList />
    </section>
  );
};

export default Home;
