const DescriptiveImage = ({ src, description, styles }) => {
  return (
    <section className="flex flex-col">
      <img src={src} alt="" className={styles + " rounded-3xl"} />
      <p className="text-stone-400 text-center md:text-left font-semibold">
        {description}
      </p>
    </section>
  );
};

export default DescriptiveImage;
