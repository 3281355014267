import { useState } from "react";
import DescriptiveImage from "components/atoms/DescriptiveImage";

const Carousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <section className="relative h-full m-6">
      <div
        className="absolute top-[40%] -left-6 pb-1.5 pl-1 pr-1.5 text-6xl text-white z-1 cursor-pointer bg-logo-creme rounded hover:bg-logo-brown"
        onClick={goToPrevious}
      >
        ❰
      </div>
      <DescriptiveImage
        src={slides[currentIndex].bg_image}
        styles=""
        description=""
      />
      <div
        className="absolute top-[40%] -right-6 pb-1.5 pr-1 pl-1.5 text-6xl text-white z-1 cursor-pointer bg-logo-creme rounded hover:bg-logo-brown"
        onClick={goToNext}
      >
        ❱
      </div>
      <div className="flex justify-center space-x-1">
        {slides.map((slide, slideIndex) => {
          const active = slideIndex === currentIndex;
          return (
            <div
              key={slideIndex}
              className={`text-4xl cursor-pointer ${
                active
                  ? "text-logo-orange"
                  : "text-logo-creme hover:text-logo-brown"
              }`}
              onClick={() => goToSlide(slideIndex)}
            >
              ●
            </div>
          );
        })}
      </div>
      {/* <div
        className={`w-full h-full bg-center bg-cover ${slides[currentIndex].bg_image}`}
      ></div> */}
    </section>
  );
};

export default Carousel;
