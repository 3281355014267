import { ReactSVG } from "react-svg";

const themes = {
  dark: "text-black fill-black",
  light: "text-white fill-white",
};

const ViewCouter = ({ title, value, theme = "dark" }) => {
  return (
    <section title={title} className={`hidden flex space-x-2 ${themes[theme]}`}>
      <ReactSVG
        src="icons/eye-fill.svg"
        className={`my-auto ${themes[theme]}`}
      />
      <span>{value}</span>
    </section>
  );
};

export default ViewCouter;
