import { Fragment } from "react";
import ReactPlayer from "react-player";

export const posts = [
  {
    id: "akademickie-zacisze",
    title: "Akademickie Zacisze",
    subtitle: "",
    date: "2022-10",
    pre_body:
      "Zapraszamy do zobaczenia materiału (122 minuty) o Wrocławskiej Szkole Przyszłości.",
    full_body: (
      <Fragment>
        <section className="flex flex-col max-w-max mx-auto">
          <ReactPlayer url={"https://youtu.be/OJs4OlgUEGg"} />
          <span className="text-xl text-center">(122 minuty)</span>
        </section>
      </Fragment>
    ),
  },
  {
    id: "konferencja-edukacji",
    title: "Konferencja Edukacji",
    subtitle: "",
    date: "2022-09",
    pre_body:
      "Zapraszamy do zobaczenia materiału (35 minut) z Konferecji Edukacji.",
    full_body: (
      <Fragment>
        <section className="flex flex-col max-w-max mx-auto">
          <ReactPlayer url={"https://youtu.be/OYI6rmc8jKc"} />
          <span className="text-xl text-center">(35 minut)</span>
        </section>
      </Fragment>
    ),
  },
];
