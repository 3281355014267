import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import countapi from "countapi-js";
import { config } from "const";

import ViewCouter from "components/atoms/ViewCounter";

import { posts } from "data/posts";

const PostFull = () => {
  const { t } = useTranslation();
  const { post_id } = useParams();
  const post = posts.find((p) => {
    return p.id === post_id;
  });
  const [postViews, setPostViews] = useState("...");

  useEffect(() => {
    countapi.hit(`${config.url}`, `${post_id}`).then((result) => {
      setPostViews(result.value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="flex justify-center">
      <div className="flex flex-col justify-center gap-10 w-full mt-20 mx-4">
        <a
          className="flex items-center justify-center gap-4 max-w-max py-2 px-4 rounded-3xl hover:bg-logo-brown hover:shadow-xl cursor-pointer uppercase bg-logo-creme"
          href="/blog"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>

          <span>Powrót do bloga</span>
        </a>
        <div className="w-full max-w-[80%] mx-auto bg-slate-200 rounded-3xl">
          <div className="bg-slate-300 min-w-full px-4 py-4 rounded-t-3xl text-left group-hover:bg-slate-400 space-y-4">
            <div className="flex flex-col justify-start md:flex-row md:justify-between align-top">
              <h1 className="text-2xl md:text-4xl font-semibold">
                {post.title}
              </h1>
              <div className="flex flex-col items-end space-y-2">
                <h6 className="text-xl md:text-2xl">{post.date}</h6>
                <ViewCouter
                  title={t("blog.post_views", { views: postViews })}
                  value={postViews}
                />
              </div>
            </div>
            <h4 className="text-xl text-slate-700">{post.subtitle}</h4>
          </div>
          <div className="p-4">{post.full_body}</div>
        </div>
      </div>
    </section>
  );
};

export default PostFull;
