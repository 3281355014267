import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-col justify-center text-center relative h-screen">
      <h1 className="text-6xl md:text-8xl">{t("not_found.main")}</h1>
      <Link
        to="/"
        className="text-4xl mt-12 font-bold underline hover:underline"
      >
        {t("not_found.come_back")}
      </Link>
    </section>
  );
};

export default NotFound;
