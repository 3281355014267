import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import countapi from "countapi-js";
import { config } from "const";

import ViewCouter from "components/atoms/ViewCounter";

const PostPre = ({ id, title, subtitle, date, pre_body }) => {
  const { t } = useTranslation();

  const post_path = `${id}`;

  const [postViews, setPostViews] = useState("...");

  useEffect(() => {
    countapi.get(`${config.url}`, `${id}`).then((result) => {
      setPostViews(result.value || 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="group bg-slate-200 my-10 rounded-3xl">
      <Link to={post_path}>
        <section className="bg-slate-300 min-w-full px-4 py-4 rounded-t-3xl text-left group-hover:bg-slate-400 space-y-4">
          <section className="flex flex-col justify-start md:flex-row md:justify-between align-top">
            <h1 className="text-2xl md:text-4xl font-semibold">{title}</h1>
            <div className="flex flex-col items-end space-y-2">
              <h6 className="text-xl md:text-2xl">{date}</h6>
              <ViewCouter
                title={t("blog.post_views", { views: postViews })}
                value={postViews}
              />
            </div>
          </section>
          <h4 className="text-xl text-slate-700">{subtitle}</h4>
        </section>
        <section className="p-4">{pre_body}</section>
      </Link>
    </section>
  );
};

export default PostPre;
