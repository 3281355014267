import { Fragment } from "react";

const sections = [
  {
    id: 0,
    name: "Dla dzieci",
    side_sections: [
      {
        id: 0,
        name: "Warsztaty Aktywności Niekonwencjonalnej",
        content: (
          <Fragment>
            <section className="w-full h-full flex flex-col justify-center space-y-2">
              <p className="leading-5">
                <span className="font-bold">Tytuł:</span>
                <br />
                <span className="uppercase">
                  Warsztaty Aktywności Niekonwencjonalnej
                </span>
              </p>
              <div>
                <p className="font-bold">Misja:</p>
                <ol className="pl-4 list-disc leading-5">
                  <li>
                    Edukacja znaczy rozwijanie możliwości każdego w wieloraki
                    sposób i na wszelkie okazje.
                  </li>
                  <li>
                    Piękne, a być może jeszcze bardziej pouczające niż piękne w
                    przygodach edukacji jest to, że punktem odniesienia dla jej
                    sensów i wartości jest to, co możliwe, a nie to, co
                    osiągnięte.
                  </li>
                  <li>
                    Warsztaty aktywności niekonwencjonalnej są posłańcami sensów
                    edukacyjnych, zawsze najlepszymi, jakie potrafimy wykreować;
                    są dla wyobraźni, wrażliwości, refleksji, mądrości – dla
                    doświadczenia, dla stawiania pytań, dla rozwoju i
                    duchowości, ale także dla wzruszenia pięknem, dla chwil
                    zadumy, dla radości po nic, czy wreszcie – dla poetyckiej
                    siły tworzącej rzeczywistość i dla nieodgadnionego.
                  </li>
                </ol>
              </div>
              <p className="leading-5">
                <span className="font-bold">Charakter:</span>
                <br />
                <span>
                  Interdyscyplinarne, multimedialne warsztaty, z założenia
                  odwołujące się do całej dotychczasowej wiedzy dzieci i
                  jednocześnie integrujące ją z codziennym doświadczeniem. Duży
                  nacisk w tym procesie położony jest na samodzielne (także w
                  grupie) stawianie pytań i udzielanie odpowiedzi przez dzieci.
                  Projekt jest inspirowany inicjatywami edukacyjnymi
                  Wrocławskiej Szkoły Przyszłości we Wrocławiu.
                </span>
              </p>
              <div>
                <p className="font-bold">Idea:</p>
                <ol className="pl-4 list-disc leading-5">
                  <li>
                    Projektowanie okazji edukacyjnych jako prowokowanie –
                    oferowanie – wybieranie, a do tego jeszcze „wiecznie głodna”
                    ciekawość, zabawa i żart – śmiech – humor, to przebogate
                    ścieżki do bezkresnego królestwa potencjałów – do „pałacu
                    możliwości” (określenie Emily Dickinson). Dalej jest już
                    apetyt na innowacje i… innowacje!
                  </li>
                  <li>
                    Rozumienie metody projektowania okazji edukacyjnych oznacza
                    metametodę opartą na heurystycznej triadzie: warunki
                    wyjściowe – zadania otwarte i/lub zamknięte – możliwości
                    wyboru; oznacza jednocześnie, iż podstawowe sensy i wartości
                    edukacyjne leżą w zadaniach otwartych, które mają
                    nieskończoną wielość dróg dochodzenia do wyników i samych
                    wyników!
                  </li>
                  <li>
                    Projektowane okazje edukacyjne oznaczają równocześnie
                    uczenie się oryginalności myślenia, czyli uczenie się
                    zachowań twórczych. Przy zadaniach otwartych owo myślenie
                    manifestuje się poprzez działania: poprzez malowanie,
                    pisanie, robienie, lepienie, konstruowanie, itd.
                  </li>
                  <li>
                    <span className="italic">Jak zabawą świat poznawać</span>,{" "}
                    <span className="italic">Jak bajkami mosty budować</span>,{" "}
                    <span className="italic">Jak podróżować bez map</span> – to
                    trzy pryncypialne szczeble porządku konstruowania narracji o
                    świecie, o człowieku i o związkach człowieka ze światem – w
                    edukacji z wyobraźnią.
                  </li>
                </ol>
              </div>
              <p className="leading-5">
                <span className="font-bold">Uczestnicy:</span>
                <br />
                <span>Dzieci w wieku 6-10 lat.</span>
              </p>
              <p>
                W przyszłości planujemy warsztaty dla innych grup wiekowych.
              </p>
            </section>
          </Fragment>
        ),
      },
    ],
  },
  {
    id: 1,
    name: "Dla edukatorów",
    side_sections: [
      {
        id: 0,
        name: "Innowacyjne Kształcenie",
        content: (
          <Fragment>
            <section className="w-full h-full flex flex-col justify-center space-y-2">
              <p className="leading-5">
                <span className="font-bold">Tytuł kursu:</span>
                <br />
                <span className="uppercase">
                  Nowe jakości innowacyjnego kształcenia: Nauczyciel między
                  żywiołami a narzędziami
                </span>
              </p>
              <div>
                <p className="font-bold">Misja:</p>
                <ol className="pl-4 list-disc">
                  <li>Drożdże dla edukacji – prowokowanie zmian.</li>
                  <li>Praktyczne przekraczanie niemocy wykształcenia.</li>
                  <li>
                    Nadawanie priorytetu szkoleniom w nowych metodach uczenia.
                  </li>
                </ol>
              </div>
              <p className="leading-5">
                <span className="font-bold">Charakter:</span>
                <br />
                <span>
                  Proinnowacyjny z walorami warsztatu i wizualizacji (40-60
                  godz.)
                </span>
              </p>
              <div>
                <p className="font-bold">Idea:</p>
                <ol className="pl-4 list-disc">
                  <li>
                    Promocja praktycznego uczenia się uniwersalnych wzorców
                    zachowań innowacyjnych w modelu:{" "}
                    <span className="font-bold">
                      prowokacje – projektowanie okazji edukacyjnych –
                      kreatywność / niekonwencjonalność / przedsiębiorczość
                    </span>
                    .
                  </li>
                  <li>Światy warsztatów – siedem zasad.</li>
                  <li>
                    Poznanie mocy inwencji jako struktury działania
                    „imaginatorów – prowokatorów”.
                  </li>
                  <li>Nauczyciel sam jest ścieżką…</li>
                </ol>
              </div>
              <p className="leading-5">
                <span className="font-bold">Uczestnicy:</span>
                <br />
                <span>
                  Nauczyciele, studenci studiów pedagogicznych i innych nauk
                  społecznych oraz humanistycznych, liderzy i wolontariusze
                  organizacji pozarządowych, animatorzy projektów obywatelskich,
                  „wychowawcy uliczni”, „wolni strzelcy”, inni chętni.
                </span>
              </p>
            </section>
          </Fragment>
        ),
      },
    ],
  },
];

export default sections;
