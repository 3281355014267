const Highway = () => {
  return (
    <section className="flex justify-center">
      <section className="w-full max-w-[80%] my-10 bg-slate-200 rounded-3xl">
        <section className="bg-slate-300 min-w-full px-4 py-4 rounded-t-3xl text-left group-hover:bg-slate-400 space-y-4">
          <h1 className="text-2xl md:text-4xl font-semibold">
            GOŚCIŃCE I STACYJKI EDUKACJI
          </h1>
          <h4 className="text-xl text-slate-700">
            DLA WYOBRAŹNI – DLA PROWOKOWANIA INWENCJI – DLA SPOŁECZNOŚCI
            LOKALNEJ
          </h4>
        </section>
        <section className="flex flex-col p-5 space-y-2">
          <p>
            Idea „gościńców i stacyjek edukacyjnych” przynależy do bogatej
            rodziny poszukiwań, prób, eksperymentów, alternatyw, czy pedagogii
            małych działań na rzecz przemian w oświacie. Jest jednocześnie
            swoistą odpowiedzią na rozmaite, bolesne przejawy niemocy
            tradycyjnego systemu edukacji tak w sytuacjach kryzysu, jak i
            reform.
          </p>
          <p>
            Ich idea jest szansą powołania do życia nowatorskiej formuły
            edukacji, która będzie alternatywnym i praktycznym źródłem tworzenia
            nowych jakości, innowacyjnych projektów i metod WARSZTATÓW
            AKTYWNOŚCI NIEKONWENCJONALNEJ przede wszystkim dla dzieci oraz
            młodzieży, ale nie tylko!
          </p>
          <p>
            Nasz projekt innowacyjno-wdrożeniowy jest realnym manifestem
            edukacji na rzecz małych, lokalnych integracji społeczeństwa
            obywatelskiego; inspirowania, praktycznego wspierania, czy wręcz
            uwalniania pozytywnych zmian i spontanicznej kreatywności poprzez
            wspólne/zespołowe inicjatywy i działania.{" "}
          </p>
          <p>
            Stawiamy na <span className="font-bold">WYOBRAŹNIĘ</span> –
            prowokowanie inwencji – projektowanie okazji edukacyjnych jako
            sekwencji niewypróbowanych.
          </p>
          <p>
            Stawiamy na <span className="font-bold">NARRACJĘ</span> jako sposób
            rozumienia świata – jako wizji pasji/misji kreowania drogi – jako
            konstruowanie świata i siebie.
          </p>
          <p>
            Stawiamy na <span className="font-bold">INNOWACYJNOŚĆ</span> –
            przedsiębiorczość – kooperację w… życiu, w praktyce, a nie jedynie w
            poczynaniach edukacyjnych.
          </p>
          <p>
            Stawiamy na <span className="font-bold">PROSPOŁECZNOŚĆ</span> –
            wybory ponadosobiste – wybory ponadmożliwe.
          </p>
          <p>
            Stawiamy na <span className="font-bold">KREATYWNOŚĆ</span> –
            prowokacje w pozytywnych sensie – zmiany.
          </p>
          <p>
            Stawiamy na <span className="font-bold">HARMONIĘ Z NATURĄ</span>–
            wielorakie style życia w ich humanistycznych sensach i bogactwie –
            na mandalę jako drogę prowadzącą naprzód.
          </p>
          <p>
            To nasze wybory, ale przecież jako jedne z wielu możliwych; mamy tu
            swoje racje i sensy – praktykowane przez dziesięciolecia!
          </p>
        </section>
      </section>
    </section>
  );
};

export default Highway;
