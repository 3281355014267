import { Suspense, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import "index.css";
import "flag-icons/css/flag-icons.min.css";

import App from "App";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ["en", "pl"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  })
  .then(() => {
    const htmlElement = document.documentElement;
    htmlElement.lang = i18n.language;
  });

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const loadingMarkup = (
  <div className="py-y text-center">
    <h2>Loading...</h2>
  </div>
);

root.render(
  <Suspense fallback={loadingMarkup}>
    <StrictMode>
      <App />
    </StrictMode>
  </Suspense>
);
