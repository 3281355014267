const Quote = ({ quote, author }) => {
  return (
    <section className="text-right">
      <p className="italic text-gray-500">{quote}</p>
      <p>{author}</p>
    </section>
  );
};

export default Quote;
