import { Fragment, useState } from "react";
import emailjs from "emailjs-com";
import { useTranslation, Trans } from "react-i18next";
// import { NavLink } from "react-router-dom";

const ContactForm = () => {
  const [formState, setFormState] = useState("FORM");
  const [showMoreRODO, setshowMoreRODO] = useState(false);
  const { t } = useTranslation();

  function toggleshowMoreRODO() {
    setshowMoreRODO(!showMoreRODO);
  }

  emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

  function sendEmail(e) {
    e.preventDefault();

    if (formState === "FORM") {
      // console.log("sent");
      setFormState("SENDING");

      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          e.target
        )
        .then((res) => {
          setFormState("THANKS");
        })
        .catch((err) => {
          setFormState("ERROR");
          console.log(err);
        });
    }
  }

  return (
    <div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
      <div className="bg-gray-100 p-5 md:p-10 rounded-3xl max-w-lg mx-auto lg:max-w-none">
        {formState === "FORM" && (
          <Fragment>
            <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl text-center md:text-left">
              {t("contact.form.title")}
            </h2>
            <form className="grid grid-cols-1 gap-y-6" onSubmit={sendEmail}>
              <div>
                <label htmlFor="email" className="sr-only">
                  {t("contact.form.email")}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:border-black rounded-md bg-none"
                  placeholder={t("contact.form.email")}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity(t("contact.form.errors.email"))
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </div>
              <div>
                <label htmlFor="subject" className="sr-only">
                  {t("contact.form.subject")}
                </label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-black focus:border-black rounded-md"
                  placeholder={t("contact.form.subject")}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity(t("contact.form.errors.subject"))
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  {t("contact.form.phone")}
                </label>
                <input
                  type="tel"
                  pattern="\+?[0-9]*(\s{1}[0-9]+)*"
                  maxLength="30"
                  name="phone"
                  id="phone"
                  className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:border-black rounded-md"
                  placeholder={t("contact.form.phone")}
                  defaultValue={""}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(t("contact.form.errors.phone"))
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  {t("contact.form.message")}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={5}
                  maxLength="2000"
                  className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:border-black border rounded-md"
                  placeholder={t("contact.form.message")}
                  defaultValue={""}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="rodo"
                    name="rodo"
                    className="flex-none w-6 h-6"
                    required
                    onInvalid={(e) =>
                      e.target.setCustomValidity(t("contact.form.errors.rodo"))
                    }
                  />
                  <label className="text-justify" htmlFor="rodo">
                    <Trans
                      defaults={t("contact.form.rodo.basic")}
                      values={{ foundation: t("common.foundation_name") }}
                      components={{ bold: <strong /> }}
                    />
                    {" *"}
                  </label>
                  {/* <label htmlFor="privacy_policy">
                    {t("contact.form.privacy_policy.basic_text")}{" "}
                    <NavLink
                      to={"/polityka-prywatnosci"}
                      className="text-indigo-500"
                    >
                      {t("contact.form.privacy_policy.link_text")}
                    </NavLink>
                    {". *"}
                  </label> */}
                </div>
                {showMoreRODO && (
                  <section className="text-justify">
                    <Trans
                      defaults={t("contact.form.rodo.more")}
                      values={{ foundation: t("common.foundation_name") }}
                      components={{ bold: <strong />, u: <u /> }}
                    />{" "}
                  </section>
                )}
                <span
                  className="max-w-max text-indigo-500 hover:cursor-pointer"
                  onClick={toggleshowMoreRODO}
                >
                  {showMoreRODO ? t("common.less") : t("common.more")}
                </span>
              </div>
              <div>
                <span>{t("contact.form.obligatory")}</span>
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-3xl text-white bg-logo-brown hover:bg-logo-creme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                >
                  {t("contact.form.submit")}
                </button>
              </div>
            </form>
          </Fragment>
        )}

        {formState === "THANKS" && (
          <section className="text-center">
            <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {t("contact.thanks.title")}
            </h2>
            <p className="my-3 text-lg leading-6 text-gray-500">
              {t("contact.thanks.text")}
            </p>
            <button
              className="mt-5 px-6 py-2 rounded-3xl bg-logo-brown hover:bg-logo-creme text-white"
              onClick={() => setFormState("FORM")}
            >
              {t("common.back")}
            </button>
          </section>
        )}

        {formState === "SENDING" && (
          <section className="text-center">
            <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {t("contact.sending.title")}
            </h2>
          </section>
        )}

        {formState === "ERROR" && (
          <section className="text-center">
            <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {t("contact.form_not_sent.title")}
            </h2>
            <p className="my-3 text-lg leading-6 text-gray-500">
              {t("contact.form_not_sent.text")}
            </p>
            <button
              className="mt-5 px-6 py-2 rounded-3xl bg-logo-brown hover:bg-logo-creme text-white"
              onClick={() => setFormState("FORM")}
            >
              {t("common.back")}
            </button>
          </section>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
