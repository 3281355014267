import { useState } from "react";
// import { useTranslation } from "react-i18next";

import sections from "data/workshops_sections";

const Workshops = () => {
  const [mainContent, setMainContent] = useState(0);
  const [sideContent, setSideContent] = useState(0);

  function changeMainContent(section) {
    setMainContent(section);
    setSideContent(0);
  }

  // const { t } = useTranslation();
  return (
    <section className="mt-[50px] mx-1 flex flex-col text-center space-y-10">
      {/* <section className="max-w-max p-4 mx-auto bg-logo-creme rounded-3xl flex flex-col md:flex-row text-2xl justify-center text-center md:text-left md:space-x-1">
        <p>
          {t("about_us.status")}
          <span className="hidden md:inline">{" - "}</span>
        </p>
        <a
          className="text-indigo-500 font-semibold"
          href="statut.pdf"
          target="_blank"
          type="application/pdf"
          download="statut.pdf"
        >
          {t("about_us.status_download")}
        </a>
      </section> */}
      <section className="m-5 p-5 rounded-3xl bg-gray-400">
        <section className="mb-3 p-3 bg-gray-300 rounded-3xl flex flex-row flex-wrap justify-around">
          {sections.map((section) => {
            const styles =
              "my-1 py-2 px-4 rounded-3xl hover:bg-logo-brown hover:shadow-xl cursor-pointer uppercase";
            const active = mainContent === section.id;
            return (
              <span
                key={section.id}
                className={`${styles} ${
                  active
                    ? "bg-logo-orange hover:bg-logo-orange"
                    : "bg-logo-creme"
                }`}
                onClick={() => changeMainContent(section.id)}
              >
                {section.name}
              </span>
            );
          })}
        </section>
        <section className="flex flex-col md:flex-row items-stretch">
          <div className="md:w-64 p-3 flex flex-col shrink-0 content-start bg-gray-300 rounded-t-3xl md:rounded-tr-none md:rounded-l-3xl">
            {sections[mainContent].side_sections.map((side_section) => {
              const styles =
                "w-3/4 mx-auto my-2 p-2 rounded-3xl hover:bg-logo-creme cursor-pointer";
              const active = sideContent === side_section.id;
              return (
                <span
                  key={side_section.id}
                  className={`${styles} ${
                    active
                      ? "bg-logo-orange hover:bg-logo-orange"
                      : "bg-logo-brown"
                  }`}
                  onClick={() => setSideContent(side_section.id)}
                >
                  {side_section.name}
                </span>
              );
            })}
          </div>
          <div className="grow p-2 bg-gray-100 text-left rounded-b-3xl md:rounded-bl-none md:rounded-r-3xl">
            {sections[mainContent].side_sections[sideContent].content}
          </div>
        </section>
      </section>
    </section>
  );
};

export default Workshops;
