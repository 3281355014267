import { Menu } from "@headlessui/react";

import i18next from "i18next";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "pl",
    name: "Polski",
    country_code: "pl",
  },
];

const changeLanguage = (code) => {
  i18next.changeLanguage(code);
  const htmlElement = document.documentElement;
  htmlElement.lang = i18next.language;
};

const ChangeLanguageButton = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <Menu as="div" className="z-50 mx-5 relative">
      <Menu.Button className="block h-8 h-8 rounded-full overflow-hidden outline-none">
        <svg
          className="h-full w-full object-cover hover:stroke-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
          />
        </svg>
      </Menu.Button>

      <Menu.Items className="w-24 bg-neutral-200 rounded-lg p-1 mt-1 shadow-xl absolute right-10 top-[-20px] lg:right-0 lg:top-auto outline-none">
        {languages.map(({ code, name, country_code }) => (
          <Menu.Item disabled={code === currentLanguageCode} key={country_code}>
            {({ active, disabled }) => (
              <button
                className={`w-full text-left block rounded-lg py-1 text-sm outline-none
                ${
                  disabled
                    ? "opacity-25"
                    : active
                    ? "bg-logo-orange text-white"
                    : ""
                }`}
                onClick={() => changeLanguage(code)}
                // disabled={code === currentLanguageCode}
                // style={{ opacity: code === currentLanguageCode ? 0.25 : 1.0 }}
              >
                <span className={`fi fi-${country_code} mx-2`}></span>
                {name}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default ChangeLanguageButton;
