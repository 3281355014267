import { useTranslation } from "react-i18next";

import NavOption from "components/atoms/NavOption";
import GoToHomePageButton from "components/atoms/GoToHomePageButton";
import ChangeLanguageButton from "components/atoms/ChangeLanguageButton";

const NavBar = () => {
  const { t } = useTranslation();

  return (
    <header className="bg-logo-brown">
      <nav className="w-full mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-2 flex items-center justify-between border-b border-black lg:border-none">
          <div className="flex items-center">
            <GoToHomePageButton />
            <div className="hidden ml-10 space-x-6 lg:block">
              <NavOption optionName={t("navbar.about_us")} linkTo={"/o-nas"} />
              <NavOption
                optionName={t("navbar.workshops")}
                linkTo={"/warsztaty"}
              />
              <NavOption
                optionName={t("navbar.highway")}
                linkTo={"/gosciniec"}
              />
              <NavOption optionName={t("navbar.blog")} linkTo={"/blog"} />
              <NavOption optionName={t("navbar.contact")} linkTo={"/kontakt"} />
            </div>
          </div>
          <div className="ml-10 space-x-4">
            <ChangeLanguageButton />
          </div>
        </div>
        <div className="py-4 hidden md:flex lg:hidden flex-wrap justify-around space-x-4 ">
          <NavOption optionName={t("navbar.about_us")} linkTo={"/o-nas"} />
          <NavOption optionName={t("navbar.workshops")} linkTo={"/warsztaty"} />
          <NavOption optionName={t("navbar.highway")} linkTo={"/gosciniec"} />

          <NavOption optionName={t("navbar.blog")} linkTo={"/blog"} />
          <NavOption optionName={t("navbar.contact")} linkTo={"/kontakt"} />
        </div>
        <div className="py-4 flex flex-col justify-center space-y-2 md:hidden">
          <section className="w-full flex justify-around">
            <NavOption optionName={t("navbar.about_us")} linkTo={"/o-nas"} />
            <NavOption
              optionName={t("navbar.workshops")}
              linkTo={"/warsztaty"}
            />
            <NavOption optionName={t("navbar.highway")} linkTo={"/gosciniec"} />
          </section>
          <section className="w-full flex justify-around">
            <NavOption optionName={t("navbar.blog")} linkTo={"/blog"} />
            <NavOption optionName={t("navbar.contact")} linkTo={"/kontakt"} />
          </section>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
