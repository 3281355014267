const DesciptionTile = ({ title, link, children }) => {
  return (
    <div className="flex flex-col gap-4 p-4 bg-gray-300 rounded-2xl">
      <h2 className="text-2xl">{title}</h2>
      {children}
      <div className="flex justify-end py-2 border-t border-gray-400">
        <a className="flex items-center gap-4 text-indigo-500" href={link}>
          <span>Czytaj wiecej</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            s
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default DesciptionTile;
