import { Fragment } from "react";
import ReactPlayer from "react-player";

import DescriptiveImage from "components/atoms/DescriptiveImage";
import DesciptionTile from "components/atoms/DescriptionTile";
import Quote from "components/atoms/Quote";
import Carousel from "components/molecules/Carousel";

const sections = [
  {
    id: 0,
    name: "Fundacja",
    side_sections: [
      {
        id: 0,
        name: "Logo",
        content: (
          <Fragment>
            <section className="w-full h-full flex justify-center items-center">
              <img src="images/logos/full-512.png" alt="" className="w-96" />
            </section>
          </Fragment>
        ),
      },
      {
        id: 1,
        name: "Motto",
        content: (
          <Fragment>
            <section className="h-full flex flex-col space-y-2 justify-center items-center">
              <Quote
                quote="Edukacja - jak poezja - jest sposobem patrzenia na świat."
                author="Ryszard Maciej Łukaszewicz"
              />
              <DescriptiveImage
                src="images/about-us/1.jpg"
                styles=""
                description=""
              />
            </section>
          </Fragment>
        ),
      },
      {
        id: 2,
        name: "Cele",
        content: (
          <Fragment>
            <ol className="pl-4 list-decimal">
              <li>
                Niezależne działanie na rzecz zmian w oświacie, sprzyjających
                poczuciu wolności ludzi wobec alternatyw w edukacji - także
                własnej i swoich dzieci.
              </li>
              <li>
                Optymalizowanie szans rozwojowych i edukacyjnych wedle własnych
                aspiracji każdego, wedle samodzielnego wyboru dróg uczenia się.
              </li>
              <li>
                Respektowanie ekologicznego porządku życia w świetle zmian
                cywilizacyjnych.
              </li>
              <li>
                Wprowadzanie niekonwencjonalnych inicjatyw edukacyjnych jako
                ważnego źródła innowacji społecznych, poprzez wyzwolenie
                ludzkiej inwencji i twórczej energii.
              </li>
            </ol>
          </Fragment>
        ),
      },
      {
        id: 3,
        name: "Działania",
        content: (
          <Fragment>
            <p>Fundacja realizuje swoje cele poprzez:</p>
            <ol className="pl-4 list-decimal">
              <li>
                Oferowanie i wspomaganie projektów praktycznych innowacji na
                rzecz alternatyw w edukacji, a w szczególności w przedszkolu i
                szkole podstawowej.
              </li>
              <li>
                Oferowanie i wspomaganie warsztatów aktywności
                niekonwencjonalnej w obszarze oświaty i inicjatyw obywatelskich.
              </li>
              <li>
                Promocję i wspomaganie inicjatyw na rzecz odkrywania dróg
                ekologicznego porządku życia i bycia, ich bogactwa i
                różnorodności w harmonii z przyrodą.
              </li>
              <li>
                Inicjowania i tworzenia wolnych stref innowacji i eksperymentów
                w edukacji/kształceniu – w środowiskach akademickich i
                pozaakademickich; tworzenie małych, lokalnych „gościńców i
                stacyjek”, uwalniania pozytywnych zmian i spontanicznej
                kreatywności poprzez wspólne/zespołowe działania.
              </li>
              <li>
                Wydawanie zeszytów „Drożdże dla edukacji – prowokowanie zmian”,
                publikowanie prac naukowych i popularnonaukowych, organizowanie
                seminariów warsztatowych, otwartych spotkań, prezentowanie
                autorskich materiałów multimedialnych i filmowych.
              </li>
              <li>
                Kształcenie i doskonalenie adeptów i liderów nowych jakości
                edukacji w kolejnych dekadach XXI wieku; kształcenie animatorów
                warsztatów aktywności niekonwencjonalnej.
              </li>
              <li>
                Uczenie autorskiej metody projektowania okazji edukacyjnych oraz
                narracyjnego tworzenia układu i doboru treści edukacyjnych,
                uczenia sztuki rozwijania i pożytkowania wyobraźni dla
                odmieniania świata na lepsze i przekraczania siebie.
              </li>
            </ol>
          </Fragment>
        ),
      },
    ],
  },
  {
    id: 1,
    name: "Placówki",
    side_sections: [
      {
        id: 0,
        name: "Prądy edukacyjne",
        content: (
          <Fragment>
            <section className="w-full h-full flex flex-col gap-4 p-8">
              <p className=" text-xl bg-gray-400 rounded-2xl p-4">
                Poniżej znajduje się kilka prądów edukacyjnych, które wymienia
                Maria Hawranek w swojej książce pt. “Szkoły, do których chce się
                chodzić są bliżej, niż myślisz!”.
              </p>
              <div className="flex flex-col gap-4 p-4 bg-gray-300 rounded-2xl">
                <h1 className="text-2xl">Ruch Budzących się Szkół</h1>
                <p>
                  Oddolny ruch zainicjowany przez neurodydaktyczkę Marzenę
                  Żylińską formalnie obejmuje ponad siedemdziesiąt placówek w
                  całej Polsce, ale sieć szkół i belfrów jest w rzeczywistości
                  dużo większa (część nauczycieli pracuje w środowiskach, w
                  których dyrektor jeszcze nie sprzyja zmianie). Dołączają do
                  Ruchu szkoły, które chcą przejść od kultury nauczania do
                  kultury uczenia się i stworzyć miejsca przyjazne dla
                  wszystkich członków szkolnej społeczności. Publiczne i
                  niepubliczne, wielkie i małe, w miastach i na wsiach. Nazywają
                  się szkołami i nauczycielami w drodze, wymieniają się
                  doświadczeniami i inspiracjami. Fundacja Budząca się Szkoła
                  ledwie nadąża z odpowiadaniem na wiadomości telefony. Ruch
                  jest neutralny politycznie, religijnie i światopoglądowo. Nie
                  ma sztywnego programu. Proponuje wartości i wskazuje kierunki
                  (sugeruje na przykład, by zamiast jedynek wstawiać ocenę
                  “jeszcze nie” albo “spróbuj jeszcze raz”), jednak niczego nie
                  narzuca, zgodnie z założeniem: nikt nie wie lepiej, czego
                  potrzeba szkole, niż rodzice, nauczyciele i dzieci, którzy tę
                  szkołę tworzą. Chodzi o to, by to oni wzięli na siebie
                  odpowiedzialność. Do ruchu należą na przykład publiczna szkoła
                  przy Malborskiej w Krakowie, Zespół Szkół Sportowych w
                  Siemianowicach Śląskich czy publiczna podstawówka w Lipowej
                  koło Żywca. Aktualną mapę można znaleźć{" "}
                  <a
                    className="text-indigo-500"
                    href="https://budzacasieszkola.edu.pl"
                  >
                    tutaj
                  </a>
                  . Fanpage jest obserwowany przez ponad pięćdziesiąt tysięcy
                  osób. Działa też{" "}
                  <a
                    className="text-indigo-500"
                    href="https://www.facebook.com/budzacasieszkola"
                  >
                    zamknięta grupa dla poszukujących nauczycieli i rodziców.
                  </a>
                </p>
              </div>
              <div className="flex flex-col gap-4 p-4 bg-gray-300 rounded-2xl">
                <h1 className="text-2xl">Plan daltoński</h1>
                <p>
                  Plan daltoński oparty jest na trzech filarach: wolności,
                  samodzielności i współpracy. “Nie wolno robić ci tego, co
                  dziecko może zrobić samo” - mówiła Amerykanka Helen Parkhurst,
                  twórczyni tej koncepcji. Była zwolenniczka samodzielnej pracy
                  dziec iwe własnym tempie, osobno i razem z rówieśnikami.
                  Polskie Stowarzyszenie Dalton pod kierunkiem Katarzyny Dryjas
                  szkoli i certyfikuje placówki oraz nauczycieli, organizuje
                  konferencje, współtworzy studia podyplomowe na Uniwersytecie
                  im. adama Mickiewicza w Poznaniu i skupia pasjonatów tego
                  podejścia. Fundacja Plandaltoński.pl, prowadzona przez
                  małżeństwo Annę i Roberta Sowińskich, zajmuje się przede
                  wszystkim szerzeniem świadomości na temat tej koncepcji,
                  kursami i szkoleniami. Wśród jej edukatorów jest między innymi
                  opisana w tej książce Wiesława Mitulska. Co niedzielę na jej
                  fanpage’u Sowińscy prowadzą bezpłatne webinaria, mają też swój
                  podcast Od nauczania do uczenia się i wydawnictwo, które
                  zainaugurowali pozycją Pedagogika planu daltońskiego. Listę
                  certyfikowanych placówek daltońskich (między innymi w Ostrowcu
                  Świętokrzyskim, Gdańsku, Warszawie, Poznaniu i Ostrołęce)
                  można znaleźć na stronie Polskiego Stowarzyszenia Dalton.
                  Łącznie jest ich pięćdziesiąt sześć, w procesie certyfikacji -
                  trzydzieści. Ostatecznie to jednak rodzic będzie musiał
                  osobiście sprawdzić, w jakim stopniu placówka realizuje
                  deklarowane wartości. W podejściu Parkhurst nie jest istotne,
                  kto jest najlepszy - liczy się holistyczny rozwój dziecka.
                  Bywa też, że kadra się zmienia, wraz z nią sposób pracy, a
                  certyfikat zostaje. Wiele szkół w Polsce nie zabiega zresztą o
                  ich przyznanie, ale pracuje w duchu koncepcji Helen Parkhurst.
                  Więcej o planie daltońskim można przeczytać na stronach
                  wymienionych inicjatyw (na przykład{" "}
                  <a
                    className="text-indigo-500"
                    href="https://www.plandaltonski.pl"
                  >
                    Plan daltonski
                  </a>
                  ) i w książkach wydawnictwa SOR-MAN, a także w kwartalniku
                  “Inspiracje Daltońskie. Teoria i Praktyka” wydawanym przez{" "}
                  <a className="text-indigo-500" href="https://dalton.org.pl">
                    Polskie Stowarzyszenie Dalton
                  </a>
                  .
                </p>
              </div>
              <DesciptionTile
                title="Pedagogika fröblowska"
                link="http://www.froebel.pl"
              >
                <p>
                  W Polsce powoli odradza się koncepcja przedszkoli według
                  Friedricha Fröbla, ojca nowożytnego wychowania przedszkolnego,
                  twórcy ogródków dziecięcych. Jego podejście opiera się na
                  jedności wszechświata, szacunku dla indywidualności oraz
                  wartości zabawy w rozwoju dziecka. Ważne jest doświadczanie
                  świata i rozwój nie tylko intelektu. Propagowaniem tej
                  pedagogiki zajmuje się zespół Froebel.pl, który od siedmiu lat
                  szkoli nauczycieli przedszkolnych i wczesnoszkolnych. W maju
                  2021 roku ich autorski program realizowało ponad tysiąc
                  przedszkoli. Nie zawsze całe przedszkole pracuje według tej
                  metody, bywa, że robi to jedna grupa w danej placówce, czasem
                  dwie. Nie wszystkie miejsca starają się zresztą o stosowny
                  certyfikat. Łącznie takich grup przedszkolnych i w klasach
                  I-III (certyfikowanych i bez certyfikatu) jest obecnie w
                  Polsce około dwóch tysięcy. Aby je znaleźć, najlepiej napisać
                  lub zadzwonić do Froebel.pl. W trakcie wizyty w przedszkolu
                  warto zwrócić uwagę, czy są w nim Dary Fröbla (oryginalne
                  klocki zaprojektowane przez niemieckiego pedagoga), czy
                  dziedzi codziennie pracują w kącikach zabawowo-zadaniowych i
                  czy kadra pedagogiczna została w tej metodzie przeszkolona.
                  Rodzice mogą też podpytać o placówki godne polecenia na grupie
                  facebookowej{" "}
                  <a className="text-indigo-500" href="“Rodzice w akcji”">
                    “Rodzice w akcji”
                  </a>
                  .
                </p>
              </DesciptionTile>
            </section>
          </Fragment>
        ),
      },
      {
        id: 1,
        name: "Lista placówek",
        content: (
          <Fragment>
            <section className="w-full h-full flex gap-4 flex-col p-8">
              <DesciptionTile
                title="Częstochowa.ALA"
                link="http://alacz.edu.pl"
              >
                <p>
                  “ALA” Autorskie Liceum Artystyczne i Akademickie w
                  Częstochowie - to alternatywna placówka ze zindywidualizowanym
                  planem nauki dla każdego ucznia. Jest oparta na autorskiej
                  koncepcji Mariusza Budzyńskiego, stawiającej przede wszystkim
                  na budowanie relacji z uczniem. Zamiast wychowawstwa klasowego
                  wprowadzony jest tutoring (co doskonale sprawdziło się również
                  w pandemii). To uczeń decyduje, jaki poziom kompetencji chce
                  osiągnąć z poszczególnych przedmiotów, i podchodzi do
                  sprawdzenia wiedzy i umiejętności, kiedy jest na to gotowy.
                  Nauczyciel przy wystawianiu oceny uwzględnia samoocenę ucznia.
                  Uczeń może odmówić przyjęcia oceny i poprawiać ją aż do
                  uzyskania satysfakcji, może być też promowany w dowolnym
                  momencie roku szkolnego. Jest to szkoła niepubliczna o
                  uprawnieniach publicznej, a jednocześnie eksperymentalna.
                  Znajduje się pod opieką Uniwersytetu
                  Humanistyczno-Przyrodniczego im. Jana Długosza w Częstochowie.
                </p>
                <p>
                  O pierwotnej inicjatywie z Wrocławia można przeczytać{" "}
                  <a className="text-indigo-500" href="https://ale.edu.pl">
                    tutaj
                  </a>
                  .
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Dymaczewo Stare. Kolektyw edukacyjny"
                link="http://kolektywedukacyjny.pl"
              >
                <p>
                  W niewielkiej wiosce pod Poznaniem działa Stowarzyszenie
                  Kolektyw Edukacyjny, założone między innymi przez Remigiusza
                  Pankiewicza i Paulinę Rogowicz. Zajmuje się propagowaniem
                  alternatywy opartej na demokratycznych wartościach. prowadzą
                  tu domowo-leśne przedszkole “NaBosaka” i szkołę demokratyczną
                  “Gromada”. Przygotowują też wydarzenia związane z edukacją
                  alternatywną, ekologiczną i psychoedukacją dla rodziców i
                  nauczycieli. Od kilku lat zajmują się organizowaniem
                  Ogólnopolskich Spotkań Edukacji Alternatywnej. Więcej
                  informacji można znaleźć na Facebooku:
                  <a
                    className="text-indigo-500"
                    href="https://www.facebook.com/gromada.edu"
                  >
                    {" "}
                    gromada
                  </a>{" "}
                  oraz
                  <a
                    className="text-indigo-500"
                    href="https://www.facebook.com/przedszkolenabosaka"
                  >
                    {" "}
                    przedszkolenabosaka
                  </a>
                  .
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Gdańsk. Thinking Zone"
                link="https://thinkingzone.pl"
              >
                <p>
                  Niepubliczne innowacyjne placówki (podstawówka, liceum,
                  edukacja domowa), w których kładzie się nacisk na rozwój
                  kompetencji kluczowych we współczesnym świecie w
                  przeświadczeniu, że era pruskiej szkoły już dawno powinna się
                  skończyć. Tu kultywuje się pracę projektową oraz rozwój
                  analitycznego i kreatywnego myślenia (know-how z Harvardu),
                  dba o samopoczucie uczniów i rozwój ich inteligencji
                  emocjonalnej (co tydzień klasa ma warsztaty z psychologiem).
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Konstancin-Jeziorna. No Bell"
                link="www.nobell.edu.pl"
              >
                <p>
                  Niepubliczny zespół szkół (od żłobka do liceum) bez dzwonków,
                  podręczników, ocen. Stawiają na rozwój krytycznego myślenia,
                  inteligencji emocjonalnej, umiejętności współpracy i poczucia
                  odpowiedzialności społecznej. Uczniowie wspólnie z
                  nauczycielem ustalają reguły i zasady. Filozofia pracy wyrasta
                  z pedagogiki Marii Montessori. Szkoła dostała wiele wyróżnień,
                  między innymi jest “Szkołą z Mocą Zmieniania Świata” fundacji
                  Ashoka (podobnie jak szkoła w Radowie Małym), ma też
                  Certyfikat Jakości Centrum Edukacji Obywatelskiej.
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Poznań. Szkoła Łejery"
                link="https://lejery.pl"
              >
                <p>
                  Publiczna Szkoła Podstawowa nr 83 im. Emilii Waśniowskiej. Od
                  początku lat dziewięćdziesiątych realizuje autorski program
                  opracowany przez Elżbietę Drygas i Jerzego Hamerskiego. Jego
                  podstawowym założeniem jest wychowywanie oparte na obcowaniu
                  ze sztuką. Przy szkole działa teatr, który w 2013 roku stał
                  się Sceną Wspólną Teatru “Łejery”, oraz Centrum Sztuki Dziecka
                  w Poznaniu. Od 2019 roku realizuje eksperyment pedagogiczny
                  “Teatr jako środek wszechstronnego rozwoju ucznia - nauka
                  przedsiębiorczości” autorstwa Anny Zawadzkiej i karola Sarny.
                  Na korytarzach stoją kanapy, fotele, zabytkowy fortepian i
                  stylowa toaletka. Mieszkają tu również skrzeczące papugi.
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Warszawa. Reggio Emilia"
                link="https://www.wioski.co"
              >
                <p>
                  Wdraża popularne powiedzenie: aby wychować jedno dziecko,
                  potrzeba całej wioski. Włącza w życie i rozwój dzieci nie
                  tylko rodziców i nauczycieli, ale też lokalne środowisko. Nie
                  ma tu ustalonego z góry planu - to nauczyciele na podstawie
                  obserwacji dzieci decydują, co jest im w danym momencie
                  potrzebne i dla nich ciekawe. Dzieci bawią się raczej
                  autentycznymi przedmiotami, a nie klasycznymi zabawkami - w
                  placówkach sięgających po tę metodę próżno szukać plastiku. Z
                  jej wykorzystaniem pracuje jeszcze stosunkowo niewiele miejsc,
                  między innymi wioski - wspólnoty opiekunów rodziców i dzieci,
                  punkty opieki lub klubiki dla maluchów w wieku od roku do
                  trzech lat. W Warszawie jest ich kilkanaście (czesne od
                  półtora do dwóch i pół tysiąca złotych miesięcznie).
                  Podpowiadają też, jak własną wioskę założyć, ale nimi nie
                  zarządzają, każda wioska to coś na kształt luźnej franczyzy.
                  Rodzice w wioskach spotykają się na popołudniowych zajęciach
                  (joga, kręgi, pikniki, od czasu do czasu szkolenia i
                  warsztaty), komunikują się w specjalnych grupach w mediach
                  społecznościowych.
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Warszawa (i Lublin). Współczesne Szkoły Realne"
                link="https://www.bsr.edu.pl"
              >
                <p>
                  Bednarska Szkoła Realna - czteroletnia niepubliczna wchodząca
                  w skład Zespołu Szkół “Bednarska”. Stawia na rozwój
                  samodzielności i pracy zespołowej, a także wspieranie
                  przedsiębiorczości uczniów - podejmowanie pracy w trakcie
                  nauki w szkole lub zakładanie własnej firmy. Jest flagową
                  szkołą Ośrodka Rozwoju Szkół Realnych, którego misją jest
                  popularyzacja oryginalnego systemu edukacji w duchu koncepcji
                  szkół realnych, a nawet sprawienie, by kiedyś stały się one
                  prawnie jeszcze jednym rodzajem szkół ponadpodstawowych.
                  Według tego pomysłu powstały jeszcze: Wawerska Szkoła Realna i
                  Saska Szkoła Realna (obie w Warszawie) oraz Lubelska Szkoła
                  Realna.
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Warszawa. Zespół Szkół STO na Bemowie"
                link="https://stonabemowie.edu.pl"
              >
                <p>
                  Niepubliczna szkoła podstawowa oraz liceum, które od lat idą
                  własną ścieżką. Uczniowie mają tu prawo do popełniania błędów
                  i są zachęcani do samodzielności. Celem szkoły jest
                  przygotowanie ich do życia wśród ludzi. Dyrektorem zespołu
                  szkół od lat jest ikoniczna już postać w edukacji
                  alternatywnej: Jarosław Pytlak, który prowadzi też{" "}
                  <a
                    className="text-indigo-500"
                    href="https://www.wokolszkoly.edu.pl"
                  >
                    blog Wokół Szkoły
                  </a>{" "}
                  i fanpage o tej samej nazwie.
                </p>
              </DesciptionTile>

              <DesciptionTile
                title="Warszawa. Liceum w Chmurze"
                link="https://liceumwchmurze.pl"
              >
                <p>
                  Niepubliczna szkoła założona przez Mariusza Truszkowskiego -
                  przedsiębiorcę społecznego i wizjonera edukacji, który razem z
                  inkubatorem Who Else i Fundacją Społeczeństwo uruchomił
                  wcześniej sieć przedszkoli i żłobków “Zielona Wieża“ oraz
                  podstawówkę “Turkusowa Wieża”. Liceum umożliwia wybór
                  indywidualnej ścieżki rozwoju opartej na zainteresowaniach
                  ucznia i kształtowanie kompetencji miękkich. Obowiązkowe
                  przedmioty można realizować samodzielnie, z pomocą nauczyciela
                  lub w grupie na specjalnej platformie e-learningowej. W dużej
                  mierze pracują online, choć uczniowie, którzy korzystają ze
                  szkoły stacjonarnie, mogą w niej być codziennie. Każdy wybiera
                  swojego tutora. W szkole są między innymi sale warsztatowe,
                  pokój narad dla zespołów projektowych, a także miejsce spotkań
                  z klientami i ze wspólnikami uczniów oraz biblioteka. “W
                  naszym liceum odnajdziesz swoją ścieżkę” - piszą o sobie.
                  Liceum jest przyjazne uczniom w edukacji domowej - uczą się
                  online, przez internet konsultują się z nauczycielami. Roczne
                  egzaminy klasyfikacyjne odbywają się zdalnie. Warszawiacy mogą
                  korzystać z Miejsca Spotkań.
                </p>
              </DesciptionTile>
            </section>
          </Fragment>
        ),
      },
    ],
  },
  {
    id: 2,
    name: "Misja",
    side_sections: [
      {
        id: 0,
        name: "Drożdże",
        content: (
          <Fragment>
            <section className="flex justify-center">
              <DescriptiveImage
                src="images/about-us/9.jpg"
                styles=""
                description=""
              />
            </section>
          </Fragment>
        ),
      },
      {
        id: 1,
        name: "Zależy nam na Was",
        content: (
          <Fragment>
            <section className="flex justify-center">
              <DescriptiveImage
                src="images/about-us/11.jpg"
                styles=""
                description=""
              />
            </section>
          </Fragment>
        ),
      },
      {
        id: 2,
        name: "Nieznośny",
        content: (
          <Fragment>
            <section className="flex flex-col max-w-max mx-auto">
              <ReactPlayer url={"https://youtu.be/KhV7wYj0lQE"} />
              <span className="text-xl text-center">(12 minut)</span>
            </section>
          </Fragment>
        ),
      },
    ],
  },
  {
    id: 3,
    name: "Skąd przychodzimy",
    side_sections: [
      {
        id: 0,
        name: "Świeradów Zdrój",
        content: (
          <Fragment>
            <section className="flex justify-center">
              <DescriptiveImage
                src="images/about-us/12.jpg"
                styles=""
                description=""
              />
            </section>
          </Fragment>
        ),
      },
      {
        id: 1,
        name: "Historia",
        content: (
          <Fragment>
            <section className="space-y-2">
              <p>
                Czy robiliśmy coś wcześniej? Tak i to przez ponad trzy dekady:{" "}
                <span className="italic">nie jesteśmy znikąd!</span>
              </p>
              <p>
                Korzenie sięgają Uniwersytetu Wrocławskiego, na którym
                zawiązaliśmy projekt pod nazwą Wrocławska Szkoła Przyszłości
                (1972). Wrocławska Szkoła Przyszłości (WSP) była najstarszą taką
                inicjatywą w Polsce; miała status placówki eksperymentalnej
                nadany przez MEN jako szkoła niepubliczna. Prowadziliśmy ją z
                sukcesami przez 25 lat (zob. R. M. Łukaszewicz:{" "}
                <span className="italic">
                  Wrocławska Szkoła Przyszłości: dla duchowości – dla wyobraźni
                  – dla praktyki
                </span>
                , Wrocław, 2010 r.; R. M. Łukaszewicz:{" "}
                <span className="italic">
                  Wrocławska Szkoła Przyszłości Plus czyli lepsze jest możliwe:
                  działania praktyczne, wizje i projekcje nowego – innego –
                  twórczego
                </span>
                , Wrocław, 2020 r.).
              </p>
              <p>
                W latach 1990-2004 organizowaliśmy Salony Edukacji Ekologicznej
                pod nazwą NATURAmy (natura & my) w Świeradowie-Zdroju.
                Przesłaniem tych warsztatów było budowanie kontrapunktu między
                zagrożeniami naszej cywilizacji, a humanistycznymi niepokojami,
                które odnajdujemy w wielkich obrazach – metaforach „świata na
                opak” mistrzów P. Bruegla i H. Boscha (zob. R. M. Łukaszewicz:{" "}
                <span className="italic">
                  „Leczenie głupoty” i... czyli Salony Edukacji Ekologicznej
                </span>
                NATURAmy, Wrocław, 1996 r.).
              </p>
              <p>
                W roku 1993 uczestniczyliśmy w międzynarodowym projekcie
                edukacyjnym: <span className="italic">NAUCZYCIELE ŚWIATA</span>,
                w sekwencji filmów dokumentalnych. Projekt był realizowany z
                inicjatywy Japończyków (Japonia, USA, Australia, Kanada, Korea
                Płd., Finlandia, Polska). Zaprezentowaliśmy odcinek filmowy pod
                tytułem:{" "}
                <span className="italic">Czy człowiek ma trzecią rękę?</span>{" "}
                (ponad 50 min.; scenariusz i realizacja zajęć z dziećmi – R. M.
                Łukaszewicz). Ponadto, jesteśmy autorami ponad 30 realizacji
                filmowych i prezentacji multimedialnych.
              </p>
              <p>
                W latach 1993-2017 prowadziliśmy Międzynarodowy Ośrodek Działań
                Niekonwencjonalnych dla/w Edukacji. Stacja Edukacji Ekologicznej
                (Czerniawa-Zdrój), w ramach którego, w roku 2001,
                zorganizowaliśmy Europejskie Forum Ekologiczne Dzieci
                EURONATURAmy, dla ponad 200 uczestników; organizacja i
                prowadzenie dr K. Leksicka i prof. R. M. Łukaszewicz wraz z
                Zespołem (zob. R.M. Łukaszewicz, K. Leksicka:{" "}
                <span className="italic">Głos dzieci</span>, Wrocław, 2002 r.).
              </p>
              <p>
                Ponadto, możemy się poszczycić rozbudowanym dorobkiem badań
                akademickich, kilkudziesięcioletnimi studiami eksperymentalnymi
                oraz wieloletnimi, wielorakimi działaniami praktycznymi w kraju
                i za granicą (zob. Ryszard Maciej Łukaszewicz:{" "}
                <span className="italic">
                  Opowieści drogi: Wrocławska Szkoła Przyszłości i Fundacja
                  Wolne Inicjatywy Edukacyjne czyli każdy więcej może, mniej
                  musi. Dobre nie wystarcza, gdy lepsze jest możliwe
                </span>
                , Wrocław, 2021 r.).
              </p>
              <div>
                <p>Z końcem roku 2021 zostało zrobione, co następuje:</p>
                <ol className="pl-4 list-decimal">
                  <li>imienne powołanie Forum Konsultacji Projektów;</li>
                  <li>
                    odbyło się spotkanie w Wydziale Edukacji Urzędu
                    Marszałkowskiego we Wrocławiu;
                  </li>
                  <li>opracowano pierwszą, wstępną wersję projektu oraz</li>
                  <li>
                    opracowano koncepcję architektoniczną „Gościńca edukacji”;
                  </li>
                  <li>
                    częściowo zaktualizowano kontakty lokalne w Euroregionie
                    „Nysa” i konsultowano nowe (m.in. Gryfów, Mirsk, Świeradów,
                    Jelenia Góra, Stara Kamienica).
                  </li>
                </ol>
              </div>
            </section>
          </Fragment>
        ),
      },
    ],
  },
  {
    id: 4,
    name: "Kim jesteśmy",
    side_sections: [
      {
        id: 0,
        name: "Ryszard Maciej Łukaszewicz",
        content: (
          <Fragment>
            <Carousel
              slides={[
                {
                  id: 0,
                  bg_image: "images/about-us/1.jpg",
                },
                { id: 1, bg_image: "images/about-us/2.jpg" },
                { id: 2, bg_image: "images/about-us/3.jpg" },
                { id: 3, bg_image: "images/about-us/4.jpg" },
                { id: 4, bg_image: "images/about-us/5.jpg" },
                { id: 5, bg_image: "images/about-us/6.jpg" },
                { id: 6, bg_image: "images/about-us/7.jpg" },
                { id: 7, bg_image: "images/about-us/8.jpg" },
              ]}
            />
          </Fragment>
        ),
      },
      {
        id: 1,
        name: "Biogram",
        content: (
          <Fragment>
            <section className="space-y-2">
              <p>Założyciel i Dobry Duch Fundacji</p>
              <p>Prof. Ryszard Maciej Łukaszewicz</p>
              <div className="max-w-[80%] mx-auto">
                <Quote quote="Biografię konstruuje się życiem, a nie pisze; konstruuje i rekonstruuje ciągle od nowa, poprzez nowe i inne, ale zawsze własne interpretacje. Pamięta i wierzy, iż człowiek jest „kimś” poprzez to, że staje się sobą. Życie wodzi i zwodzi różnymi drogami, ale tak naprawdę liczy się odkrywanie drogi w sobie!" />
              </div>
              <p>
                Absolwent pedagogiki, historii i resocjalizacji na Uniwersytecie
                Wrocławskim; od 2001 r. profesor zwyczajny.
              </p>
              <p>
                Od lat 70-tych autor i lider projektów związanych z
                praktykowaniem edukacji alternatywnej, m.in.: Wrocławska Szkoła
                Przyszłości (WSP); warsztaty w cyklach rocznych i kilkuletnich
                (Wrocław, Słupsk, Kalisz); Fundacja Wolne Inicjatywy Edukacyjne:
                WIE (od 1990); Salon Edukacji Ekologicznej NATURAmy w
                Świeradowie Zdroju; Międzynarodowy Ośrodek Działań
                Niekonwencjonalnych dla/w Edukacji w Świeradowie Zdroju;
                Fundacja Alternatyw Lepszej Edukacji: Gościniec Czterech
                Żywiołów z siedzibą w Świeradowie-Zdroju (od 2019).
              </p>
              <p>
                Organizator konferencji i seminariów na temat innowacji
                edukacyjnych i studiów porównawczych systemów oświatowych w
                Polsce i na świecie.
              </p>
              <p>
                Członek i aktywny uczestnik wielu organizacji, m.in.: Komitetu
                Prognoz Polska w XXI wieku przy Prezydium PAN (1990-1993,
                1993-1995); Komitetu Nauk Pedagogicznych PAN, (1990-1992,
                1994-1996); Sekcji Nauk Społecznych KBN (1995-1996);
                Europejskiego Forum na rzecz Wolności w Edukacji – Witten,
                Niemcy (od 1989); Międzynarodowego Stowarzyszenia Edukacji
                Ekologicznej – Giessen, Niemcy (od 1994); Rady Programowej
                Stowarzyszenia Na Rzecz Wszystkich Istot – Bielsko Biała (od
                19960; Kapituły Funduszu Stypendialnego dla dzieci uzdolnionych
                Jolanty i Aleksandra Kwaśniewskich (1996-2016); Polskiego
                Towarzystwa Pedagogicznego; Rady Naukowej Polskiego Towarzystwa
                Pedagogicznego (od 2017), Polskiego Stowarzyszenia Kreatywności.
              </p>
              <p>
                Autor ponad 200 rozmaitych publikacji i artykułów, w tym 21
                własnych pozycji książkowych; inspirator i scenarzysta ponad 30
                filmów o edukacji alternatywnej i ekologicznej. Redaktor
                wydawniczych serii czasopism; m.in. biuletynu{" "}
                <span className="italic">NATURAmy</span>,{" "}
                <span className="italic">Szukamy lepszej szkoły</span>,{" "}
                <span className="italic">
                  Gazety Wolnych Inicjatyw Edukacyjnych
                </span>
                .
              </p>
              <p>
                Główne zainteresowania badawcze: innowacje i alternatywy w
                edukacji, eksperymenty szkolne, antropologia filozoficzna i
                kulturowa, problematyka globalna i ekologiczna, cywilizacyjne
                wyzwania i zagrożenia.
              </p>
              <p>
                Prywatnie miłośnik sportu (tenis, narciarstwo, jogging),
                górskiej włóczęgi, dobrej kuchni i niekonwencjonalnych,
                spontanicznych zdarzeń; artystyczna dusza Wodnika o galopującej
                wyobraźni – autor tomików{" "}
                <span className="italic">Zapach maciejki</span> (1992),{" "}
                <span className="italic">Podwórka z maciejką</span> (1997),{" "}
                <span className="italic">Wigilijne maciejki</span> (1999),{" "}
                <span className="italic">Szuflady maciejki</span> (2009),{" "}
                <span className="italic">Opłatki maciejki</span> (2014),{" "}
                <span className="italic">Zauroczenia maciejki</span> (2021).
                Przeciwnik filozofii kropki nad „i”.
              </p>
            </section>
          </Fragment>
        ),
      },
      {
        id: 2,
        name: "Siła ludzi",
        content: (
          <Fragment>
            <section className="space-y-2">
              <p>Kim jesteśmy? Ludźmi dobrej woli, aby zmieniać co nas boli.</p>
              <p>
                Jesteśmy pedagogami, ale przede wszystkim – rodzicami.
                Odnaleźliśmy się i połączyli w niezgodzie na jednowymiarowe
                rozumienie edukacji. A edukacja, to przecież{" "}
                <span className="italic">
                  możliwości kreowania możliwości ludzi
                </span>
                . To – używając metafory – sięganie/wydobywanie na jaw diamentów
                z nieskończenie bogatej „kopalni” ludzkiego potencjału. To w
                procesie edukacji – jak diamenty w rękach zmyślnych jubilerów –
                ów potencjał zyskuje radykalnie na wartości. A to, ile zyskuje
                zależy właśnie od tego, z jaką edukacją mamy/możemy mieć do
                czynienia.
              </p>
              <p>
                Dobra wola, mocna wola, to kategorie, które z trudem znajdziemy
                nawet w starych podręcznikach psychologii; można żartobliwie
                powiedzieć, że właśnie my – ludzie, łańcuchy dobrej woli –
                „maratończycy” dobiegamy z tarczą; dobra i długo trwająca wola
                bierze się z tego, co nas boli, co boli ludzi i społeczeństwo
                obywatelskie.
              </p>
              <p>
                Wspierają nas kompetentni i doświadczeni przedstawiciele
                środowisk akademickich, o znaczącym dorobku poczynań
                praktycznych w zakresie twórczych inicjatyw edukacyjnych, m.in.:
                prof. Jolanta Zwiernik, prof. Joanna Maria Garbula, prof. Beata
                Gola.
              </p>
              <p>
                Zawsze, jeśli lepsze jest możliwe, to dobre nie wystarcza; warto
                zmieniać świat na lepsze i przekraczać siebie.
              </p>
            </section>
          </Fragment>
        ),
      },
    ],
  },
  {
    id: 5,
    name: "Dokąd zmierzamy",
    side_sections: [
      {
        id: 0,
        name: "Możliwości",
        content: (
          <Fragment>
            <section className="space-y-2">
              <p>DOKĄD ZMIERZAMY? Do otwierania możliwości dla przyszłości.</p>
              <p>
                Edukacja jest wielką okazją próbowania świata i siebie. Ona
                przecież zawsze gromadzi czujne, wrażliwe, czasami oporne, ale
                zawsze chłonne stworzenia – w pierwszej kolejności dzieci i
                młodych. Właśnie dzięki niej można dokonywać rzeczy niemożliwych
                i być niezadowolonym ze zwykłego, utartego porządku ludzkiej
                egzystencji. Dla nich jest ona nie tylko „ziemią obiecaną” czy
                żeglowaniem ku „trafnym utopiom”, lecz także wielkim kęsem
                życia. Spontanicznie i z niecierpliwością, z uwagą i
                wytrwałością, pragną używać rąk i rozumu, aby doświadczać,
                bezpośrednio dotykać, a nie jedynie ogarniać myślą, zamienioną
                na czynności pozorne. Chcą ciągle od nowa stawiać znak równości
                między twórczością a zmianą, a nie odtwarzać dany ład.
                Potrzebują doświadczać siebie jako części świata, aby intuicją,
                fantazją, kreacją i zabawą, ekspresją i emocjami sprzyjać
                ekologicznemu porządkowi życia. Dlatego w obrazach przyrody
                szukają metafor dla nowych, alternatywnych inspiracji oraz
                sensów, które mają wyrazić ich oczekiwania.
              </p>
            </section>
          </Fragment>
        ),
      },
      {
        id: 1,
        name: "Zielony manifest",
        content: (
          <Fragment>
            <section className="flex justify-center">
              <DescriptiveImage
                src="images/about-us/13.jpg"
                styles=""
                description=""
              />
            </section>
          </Fragment>
        ),
      },
      {
        id: 2,
        name: "Poetyka marzeń",
        content: (
          <Fragment>
            {" "}
            <section className="space-y-2">
              <p>
                To właśnie marzenia są tymi niezwykłymi myślami obdarzonymi mocą
                sprawczą.
              </p>
              <p>
                Gaston Bachelard w Poetyce marzeń pisze przejmująco i pięknie:
              </p>
              <div className="max-w-[80%] mx-auto">
                <Quote
                  quote="Kiedy marzyciel marzenia oddalił już od siebie wszystkie
                „zainteresowania”, które zagracały codzienne życie, kiedy
                odizolował się od troski, jaką jest dla niego troska innych,
                kiedy naprawdę stał się autorem swojej samotności, kiedy
                wreszcie może marzyć o jakiejś pięknej stronie Wszechświata, nie
                licząc godzin, marzyciel odczuwa wówczas pewien byt, który się w
                nim otwiera. Nagle marzyciel taki jest marzycielem świata.
                Otwiera się na świat i świat się przed nim otwiera. Nigdy nie
                widziało się świata należycie, jeśli to, co się widziało, nie
                było przedmiotem marzenia."
                />
              </div>

              <p>Stawiamy na myśli obdarzone mocą!</p>
            </section>
          </Fragment>
        ),
      },
    ],
  },
];

export default sections;
