import { posts } from "data/posts";
import PostPre from "components/molecules/PostPre";

const PostList = () => {
  return (
    <section className="w-full max-w-[80%] md:max-w-[60%]">
      {posts.map((post) => {
        return <PostPre key={post.id} {...post} />;
      })}
    </section>
  );
};

export default PostList;
