import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NavBar from "components/organisms/NavBar";
import Footer from "components/organisms/Footer";
import Home from "pages/Home";
import AboutUs from "pages/AboutUs";
import Workshops from "pages/Workshops";
import Highway from "pages/Highway";
import Contact from "pages/Contact";
import Blog from "pages/Blog";
import PostFull from "components/molecules/PostFull";
// import PrivacyPolicy from "pages/PrivacyPolicy";

import NotFound from "pages/NotFound";
// import InBuild from "pages/InBuild";

function App() {
  return (
    <Router>
      <section className="min-h-full">
        <section className="mb-10 pb-[140px] md:pb-[96px] overflow-auto">
          <NavBar />
          <section className="max-w-screen-2xl mx-auto">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/o-nas" element={<AboutUs />} />
              <Route path="/warsztaty" element={<Workshops />} />
              <Route path="/gosciniec" element={<Highway />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:post_id" element={<PostFull />} />
              <Route path="/kontakt" element={<Contact />} />
              {/* <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} /> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </section>
        </section>
      </section>
      <Footer />
    </Router>
  );
}

export default App;
