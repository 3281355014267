import { useTranslation } from "react-i18next";

import ContactForm from "components/organisms/ContactForm";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <h2 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            {t("contact.info.title")}
          </h2>
          <p className="mt-3 text-lg leading-6 text-gray-500">
            {t("contact.info.text")}
          </p>
          <dl className="mt-8 text-base text-gray-500">
            <div className="mt-3">
              <dt className="sr-only">{t("contact.info.krs")}</dt>
              <dd className="flex justify-between">
                <span className="mx-3">{t("contact.info.krs")}</span>
                <span className="mx-3 font-bold">0000800042</span>
              </dd>
            </div>
            <div className="mt-3">
              <dt className="sr-only">{t("contact.info.nip")}</dt>
              <dd className="flex justify-between">
                <span className="mx-3">{t("contact.info.nip")}</span>
                <span className="mx-3 font-bold">6131582833</span>
              </dd>
            </div>
            <div className="mt-3">
              <dt className="sr-only">{t("contact.info.regon")}</dt>
              <dd className="flex justify-between">
                <span className="mx-3">{t("contact.info.regon")}</span>
                <span className="mx-3 font-bold">384161760</span>
              </dd>
            </div>
          </dl>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Home;
